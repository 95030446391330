import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CustomError } from 'types/error';
import { ProjectObject } from 'types/project';

type AppState = {
    activeObject: ProjectObject | undefined
    userActions: string[]
    error: CustomError | undefined;
};

const initialState: AppState = {
    activeObject: undefined,
    userActions: [],
    error: undefined,
};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        setActiveObject: (state, action: PayloadAction<ProjectObject | undefined>) => {
            state.activeObject = action.payload;
        },
        setError: (state, action: PayloadAction<CustomError | undefined>) => {
            state.error = action.payload;
        },
        setUserActions: (state, action: PayloadAction<string[]>) => {
            state.userActions = action.payload;
        },
    },
});

export default appSlice.reducer;
