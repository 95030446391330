import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PresentState } from 'types/present';

const initialState: PresentState = {
    updateNeeded: false,
};

export const presentSlice = createSlice({
    name: 'present',
    initialState,
    reducers: {
        setUpdateNeeded: (state, action: PayloadAction<boolean>) => {
            state.updateNeeded = !!action.payload;
        },
    },
});

export default presentSlice.reducer;
