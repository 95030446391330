import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SettingsState } from 'types/settings';

const initialState: SettingsState = {
    configPath: '',
    defaultLocale: '',
};

export const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<SettingsState>) => {
            state.configPath = action.payload.configPath;
            state.defaultLocale = action.payload.defaultLocale;
        },
        setDefaultLocale: (state, action: PayloadAction<string>) => {
            state.defaultLocale = action.payload;
        },
    },
});

export default settingsSlice.reducer;
