export type Action =
    | SetCameraConstraintsAction
    | EnableHotspotsAction
    | PlayAnimationAction
    | OpenModuleAction
    | HideModuleAction
    | SetCameraAction
    | SetConfigurationAction
    | SetMaterialAction
    | DragAction
    | DefinePresetAction
    | ExecutePresetAction
    | UpdateInSituationImageAction
    | UpdateLabelAction
    | UserAction;

export enum ActionType {
    SET_CAMERA = 'SET_CAMERA',
    SET_CAMERA_CONSTRAINTS = 'SET_CAMERA_CONSTRAINTS',
    OPEN_MODULE = 'OPEN_MODULE',
    HIDE_MODULE = 'HIDE_MODULE',
    PLAY_ANIMATION = 'PLAY_ANIMATION',
    ENABLE_HOTSPOTS = 'ENABLE_HOTSPOTS',
    SET_CONFIGURATION = 'SET_CONFIGURATION',
    SET_MATERIAL = 'SET_MATERIAL',
    DRAG_ANIMATION = 'DRAG_ANIMATION',
    SHOW_INTERACTION_IMAGE = 'SHOW_INTERACTION_IMAGE',
    DEFINE_PRESET_ACTIONS = 'DEFINE_PRESET_ACTIONS',
    EXECUTE_PRESET_ACTION = 'EXECUTE_PRESET_ACTION',
    UPDATE_IN_SITUATION_IMAGE = 'UPDATE_IN_SITUATION_IMAGE',
    UPDATE_LABEL = 'UPDATE_LABEL',
}

export interface UserAction extends SetCameraAction {
    id: string
    visible?: boolean
    title: string
}

export interface SFCameraAction {
    position: number[];
    target: number[];
    duration: number;
    fov?: number;
    ease?: string;
    userInteraction?: boolean;
    userInteractionDuringAnimation?: boolean;
    disableConstraintsDuringAnimation?: boolean;
    endActionsCallback?: Function;
    endActions?: any[];
}

export interface SFMaterialAction {
    materialId: string;
    channels: any;
    texture?: string;
    video?: string;
    loop?: boolean;
    mute?: boolean;
    uvScale?: number[];
    uvOffset?: number[];
    uvRotation?: number;
}

export interface SFConfigurationAction {
    enable?: string[];
    disable?: string[];
    reset?: boolean;
}

export type DragAction = {
    type: ActionType.DRAG_ANIMATION;
    data: DragActionData;
};

export type DragActionData = {
    value: number;
    animationId: string;
};

export type PlayAnimationActionData = {
    animationId: string;
    speed?: number;
    cycleMode?: string;
    seekTo?: number;
    playState?: boolean;
    userInteraction?: boolean;
    enableInteractionDuringAnimation?: boolean;
    endActions?: Action[];
};

export type SetCameraConstraintsActionData = {
    up?: number;
    down?: number;
    left?: number;
    right?: number;
    zoomIn?: number;
    zoomOut?: number;
    fov?: number;
    nearFarRatio?: number;
    position?: number[];
    target?: number[];
    useCameraConstraints?: boolean;
    usePanConstraints?: boolean;
    usePitchConstraints?: boolean;
    useYawConstraints?: boolean;
    useZoomConstraints?: boolean;
    enableContraints?: boolean;
    recenter?: boolean;
    focusOnVisibleGeometry?: boolean;
    userInteraction?: boolean;
};

export type SetCameraConstraintsAction = {
    type: ActionType.SET_CAMERA_CONSTRAINTS;
    data: SetCameraConstraintsActionData;
};

export type SetMaterialAction = {
    type: ActionType.SET_MATERIAL;
    data: SFMaterialAction;
};

export type SetCameraActionData = {
    position: number[];
    target: number[];
    duration: number;
    ease: string;
    userInteraction: boolean;
    endActions?: Action[];
    fov?: number;
};

export type DefinePresetAction = {
    type: ActionType.DEFINE_PRESET_ACTIONS;
    data: PresetActionsData[];
    endActions?: Action[];
};

export type PresetActionsData = {
    label: string;
    actions: Action[];
};

export type ExecutePresetAction = {
    type: ActionType.EXECUTE_PRESET_ACTION;
    data: ExecutePresetActionData;
};

export type ExecutePresetActionData = {
    label: string;
};

export type PlayAnimationAction = {
    type: ActionType.PLAY_ANIMATION;
    data: PlayAnimationActionData;
};

export type EnableHotspotsActionData = {
    hotspots: number[];
};

export type EnableHotspotsAction = {
    type: ActionType.ENABLE_HOTSPOTS;
    data: EnableHotspotsActionData;
};

export type SetCameraAction = {
    id?: string;
    type: ActionType.SET_CAMERA;
    data: SetCameraActionData;
};

export type UpdateLabelAction = {
    type: ActionType.UPDATE_LABEL;
    data: UpdateLabelActionData;
};

export type UpdateLabelActionData = {
    id: string;
    title?: string;
    subtitle?: string;
    text?: string;
    image?: string;
};

export type UpdateInSituationImageAction = {
    type: ActionType.UPDATE_IN_SITUATION_IMAGE;
    data: UpdateInSituationImageActionData;
};

export type UpdateInSituationImageActionData = {
    index: number;
};

export type SetConfigurationAction = {
    type: ActionType.SET_CONFIGURATION;
    data: SFConfigurationAction;
};

export type OpenModuleActionData = {
    moduleId: string;
};

export type OpenModuleAction = {
    type: ActionType.OPEN_MODULE;
    data: OpenModuleActionData;
};

export type HideModuleAction = {
    type: ActionType.HIDE_MODULE;
};
