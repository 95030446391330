/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { FC } from 'react';

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import './styles.scss';

type LoaderPopupProps = {
    visible: boolean;
};

const LoaderPopup: FC<LoaderPopupProps> = ({ visible }) => {
    const { t } = useTranslation();
    const openReadMore = () => {};

    return (
        <div className="loaderPopupContainer">
            <div
                className={clsx('loaderPopup', { visible })}
                onClick={openReadMore}
                dangerouslySetInnerHTML={{
                    __html: t('SKETCHFAB_LOADING_TEXT'),
                }}
            />
        </div>
    );
};

export default LoaderPopup;
