import { SettingsState } from 'types/settings';

import { getEnvPathFromParams, getLocaleFromParams } from './params';

// eslint-disable-next-line import/prefer-default-export
export function getSettings(): SettingsState {
    const params = new URLSearchParams(window.location.search);

    const theSettings: SettingsState = {
        configPath: getEnvPathFromParams() || './',
        defaultLocale: getLocaleFromParams(),
    };

    if (params.has('configPath')) {
        theSettings.configPath = params.get('configPath');
    }
    if (params.has('defaultLocale')) {
        theSettings.defaultLocale = params.get('defaultLocale');
    }

    if (theSettings.defaultLocale === null) {
        const theLanguageElem = document.documentElement.lang;
        if (theLanguageElem !== null) {
            theSettings.defaultLocale = theLanguageElem;
        }
    }

    return theSettings;
}
