import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
    DragActionData,
    EnableHotspotsActionData,
    PresetActionsData,
    SFCameraAction,
    SFConfigurationAction,
    SFMaterialAction,
} from 'types/action';
import {
    SFAnimation,
    SFAnimationState,
    SFAnnotation,
    SFCameraConstraints,
    SFCameraState,
    SFClickData,
    SFCycleMode,
    SFHotspotState,
    SFHotspotStateType,
    SFMaterial,
    SFSceneObject,
} from 'types/sketchfab';
import { degToRad, getSceneObjectsByName } from 'utils/sketchfab';

import SFController from '../controllers/sketchfab';

interface SketchfabState {
    api: any;
    ready: boolean;
    visible: boolean;
    error: boolean;
    showHotspots: boolean;
    showHotspotTooltips: boolean;
    animationPlaying: boolean;
    animations: SFAnimation[];
    annotations: SFAnnotation[];
    sceneObjects: SFSceneObject[];
    materials: SFMaterial[];
    userInteraction: boolean;
    enableInteractionDuringAnimation: boolean;
    cycleMode: SFCycleMode;
    speed: number;
    currentAnimation: string;
    animationEndCallback: Function | null;
    currentHotspotIndex: number;
    visibleHotspotIndexes: number[];
    visibleObjects: string[];
    animationState: SFAnimationState;
    cameraState: SFCameraState;
    cameraStateCallback: Function | null;
    hotspotState: SFHotspotState;
    modelLoadProgress: number;
    textureLoadProgress: number;
    cameraConstraints: SFCameraConstraints;
    enableCameraConstraints: boolean;
    hasDisabledCameraConstraints: boolean;
    shouldShowHotspots: boolean;
    shouldResetCamera: boolean;
    clickData: SFClickData | null;
    presetActions: PresetActionsData[];
}

const initialState: SketchfabState = {
    api: undefined,
    ready: false,
    visible: true,
    error: false,
    showHotspots: true,
    showHotspotTooltips: true,
    visibleHotspotIndexes: [],
    visibleObjects: [],
    userInteraction: true,
    animationPlaying: false,
    animations: [],
    annotations: [],
    sceneObjects: [],
    materials: [],
    cycleMode: SFCycleMode.ONE,
    speed: 1,
    modelLoadProgress: 0,
    textureLoadProgress: 0,
    currentAnimation: '',
    animationEndCallback: null,
    enableInteractionDuringAnimation: false,
    currentHotspotIndex: -1,
    animationState: SFAnimationState.IDLE,
    cameraState: SFCameraState.IDLE,
    cameraStateCallback: null,
    hotspotState: { type: SFHotspotStateType.BLUR, index: -1 },
    cameraConstraints: {},
    enableCameraConstraints: false,
    hasDisabledCameraConstraints: false,
    shouldResetCamera: false,
    shouldShowHotspots: true,
    clickData: {
        material: {
            cullFace: '',
            id: '',
            name: '',
            reflection: -1,
            channels: {},
        },
        position2D: [],
        position3D: [],
        normal: [],
    },
    presetActions: [],
};

export const sketchfabSlice = createSlice({
    name: 'sketchfab',
    initialState,
    reducers: {
        resetAll: () => ({ ...initialState }),
        setApi: (state, action: PayloadAction<any>) => {
            state.api = action.payload;
        },
        setError: (state) => {
            state.error = true;
        },
        setVisible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
        checkReady: (state) => {
            if (
                state.sceneObjects.length > 0 &&
                // state.animations.length > 0 &&
                // state.annotations.length > 0 &&
                state.materials.length > 0 &&
                state.visible
            ) {
                state.ready = true;
            }
        },
        setHotspotsVisible: (
            state,
            action: PayloadAction<EnableHotspotsActionData>,
        ) => {
            const theVisibleHotspots: number[] = [];
            for (let i: number = 0; i < state.annotations.length; i += 1) {
                if (action.payload.hotspots.indexOf(i) === -1) {
                    SFController.setHotspotVisible(state.api, i, false);
                } else {
                    SFController.setHotspotVisible(
                        state.api,
                        i,
                        state.shouldShowHotspots,
                    );
                    theVisibleHotspots.push(i);
                }
            }

            state.visibleHotspotIndexes = theVisibleHotspots;
            state.showHotspots = theVisibleHotspots.length > 0;
        },
        setShouldShowHotspots: (state, action: PayloadAction<boolean>) => {
            state.shouldShowHotspots = action.payload;

            for (let i: number = 0; i < state.annotations.length; i += 1) {
                if (state.visibleHotspotIndexes.indexOf(i) !== -1) {
                    SFController.setHotspotVisible(
                        state.api,
                        i,
                        action.payload,
                    );
                }
            }
        },
        gotoHotspot: (state, action: PayloadAction<number>) => {
            if (action.payload !== -1) {
                state.clickData = null;
                SFController.gotoHotspot(
                    state.api,
                    action.payload,
                    false,
                    false,
                );
            }
        },
        setAllHotspotsVisible: (state, action: PayloadAction<boolean>) => {
            const theVisibleHotspots: number[] = [];
            for (let i: number = 0; i < state.annotations.length; i += 1) {
                if (action.payload) {
                    theVisibleHotspots.push(i);
                    SFController.setHotspotVisible(
                        state.api,
                        i,
                        state.shouldShowHotspots,
                    );
                } else {
                    SFController.setHotspotVisible(state.api, i, false);
                }
            }

            state.visibleHotspotIndexes = theVisibleHotspots;
            state.showHotspots = action.payload;
        },
        setHotspotTooltipsVisible: (state, action: PayloadAction<boolean>) => {
            SFController.setHotspotTooltipVisible(state.api, action.payload);
            state.showHotspotTooltips = action.payload;
        },
        setPlayState: (state, action: PayloadAction<boolean>) => {
            // if (action.payload)
            //  SFController.setUserInteraction(state.api, state.enableInteractionDuringAnimation);

            SFController.setPlayState(state.api, action.payload);
        },
        setAnimation: (state, action: PayloadAction<string>) => {
            if (action.payload !== state.currentAnimation) {
                const targetAnimation = state.animations.find(
                    (animation) => animation.name === action.payload,
                );

                if (targetAnimation) {
                    SFController.setCurrentAnimationByUID(
                        state.api,
                        targetAnimation.uid,
                    );
                    state.currentAnimation = action.payload;
                }
            }
        },
        setAnimationEndCallback: (
            state,
            action: PayloadAction<Function | null>,
        ) => {
            state.animationEndCallback = action.payload;
        },
        setAnimationDrag: (state, action: PayloadAction<DragActionData>) => {
            const theAnimationId =
                action.payload.animationId || state.currentAnimation;
            const targetAnimation = state.animations.find(
                (animation) => animation.name === theAnimationId,
            );

            if (targetAnimation) {
                // First set the animation.
                if (state.currentAnimation !== theAnimationId) {
                    SFController.setCurrentAnimationByUID(
                        state.api,
                        targetAnimation.uid,
                    );
                    state.currentAnimation = targetAnimation.name;
                }

                const theTime =
                    (targetAnimation.duration / 100) * action.payload.value;
                SFController.seekTo(state.api, theTime);
            }
        },
        setHotspotIndex: (state, action: PayloadAction<number>) => {
            state.currentHotspotIndex = action.payload;
            state.shouldResetCamera = true;
        },
        unselectHotspot: (state) => {
            state.currentHotspotIndex = -1;
            state.shouldResetCamera = true;
            // SFController.unselectHotspot(state.api);
        },
        setSpeed: (state, action: PayloadAction<number>) => {
            SFController.setSpeed(state.api, action.payload);
            state.speed = action.payload;
        },
        setSeekTo: (state, action: PayloadAction<number>) => {
            SFController.seekTo(state.api, action.payload);
        },
        setModelLoadProgress: (state, action: PayloadAction<number>) => {
            if (action.payload >= state.modelLoadProgress) {
                state.modelLoadProgress = action.payload;
            }
        },
        setTextureLoadProgress: (state, action: PayloadAction<number>) => {
            if (action.payload >= state.textureLoadProgress) {
                state.textureLoadProgress = action.payload;
            }
        },
        setUserInteraction: (state, action: PayloadAction<boolean>) => {
            SFController.setUserInteraction(state.api, action.payload);
            state.userInteraction = action.payload;
        },
        setUserInteractionDuringAnimation: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.enableInteractionDuringAnimation = action.payload;
        },
        setCycleMode: (state, action: PayloadAction<SFCycleMode>) => {
            SFController.setCycleMode(state.api, action.payload);
            state.cycleMode = action.payload;
        },
        setAnimationtate: (state, action: PayloadAction<SFAnimationState>) => {
            state.animationState = action.payload;

            switch (action.payload) {
                case SFAnimationState.PLAY:
                    state.animationPlaying = true;
                    break;
                default:
                    state.animationPlaying = false;
            }
        },
        setCameraConstraints: (
            state,
            action: PayloadAction<SFCameraConstraints>,
        ) => {
            SFController.setCameraConstraints(state.api, {
                ...action.payload,
                up: action.payload.up && degToRad(action.payload.up),
                down: action.payload.down && degToRad(action.payload.down),
                left: action.payload.left && degToRad(action.payload.left),
                right: action.payload.right && degToRad(action.payload.right),
            });

            state.cameraConstraints = action.payload;

            if (action.payload.recenter) {
                SFController.recenterCamera(state.api);
            }

            if (action.payload.focusOnVisibleGeometry) {
                SFController.focusOnVisibleGeometries(state.api);
            }

            if (action.payload.enableContraints !== undefined) {
                SFController.setEnableCameraConstraints(
                    state.api,
                    action.payload.enableContraints,
                    action.payload.preventFocus || true,
                );
                state.enableCameraConstraints = action.payload.enableContraints;
            }

            if (action.payload.userInteraction !== undefined) {
                SFController.setUserInteraction(
                    state.api,
                    action.payload.userInteraction,
                );
                state.userInteraction = action.payload.userInteraction;
            }
        },
        setEnableCameraConstraints: (state, action: PayloadAction<boolean>) => {
            SFController.setEnableCameraConstraints(
                state.api,
                action.payload,
                true,
            );
            state.enableCameraConstraints = action.payload;
        },
        setCameraState: (state, action: PayloadAction<SFCameraState>) => {
            state.cameraState = action.payload;

            if (action.payload === SFCameraState.STOP) {
                state.enableInteractionDuringAnimation = false;

                if (
                    state.hasDisabledCameraConstraints &&
                    state.enableCameraConstraints
                ) {
                    state.hasDisabledCameraConstraints = false;
                    SFController.setEnableCameraConstraints(
                        state.api,
                        true,
                        true,
                    );
                }

                if (state.enableCameraConstraints && state.shouldResetCamera) {
                    state.shouldResetCamera = false;
                    SFController.resetCamera(state.api);
                }
            }
        },
        setCameraActionEndCallback: (
            state,
            action: PayloadAction<Function | null>,
        ) => {
            state.cameraStateCallback = action.payload;
        },
        setHotspotState: (state, action: PayloadAction<SFHotspotState>) => {
            state.hotspotState = action.payload;
        },
        setAnimations: (state, action: PayloadAction<SFAnimation[]>) => {
            state.animations = action.payload;
        },
        setAnnotions: (state, action: PayloadAction<SFAnnotation[]>) => {
            state.annotations = action.payload;
        },
        setSceneObjects: (state, action: PayloadAction<SFSceneObject[]>) => {
            state.sceneObjects = action.payload;

            Object.values(action.payload).forEach((o) => {
                const { name } = o;
                if (name != null && !state.visibleObjects.includes(name)) {
                    state.visibleObjects.push(o.name);
                }
            });
        },
        setMaterials: (state, action: PayloadAction<SFMaterial[]>) => {
            state.materials = action.payload;
        },
        setMaterialAction: (state, action: PayloadAction<SFMaterialAction>) => {
            SFController.setMaterialAction(state.api, action.payload);
        },
        setClickData: (state, action: PayloadAction<SFClickData | null>) => {
            state.clickData = action.payload;
        },
        setCameraAction: (state, action: PayloadAction<SFCameraAction>) => {
            // First disable camera constraints. This prevents camera from moving to exact position
            if (
                state.enableCameraConstraints &&
                action.payload.disableConstraintsDuringAnimation === true
            ) {
                state.hasDisabledCameraConstraints = true;
                SFController.setEnableCameraConstraints(state.api, false, true);
            }

            state.shouldResetCamera = true;

            /*
            if (action.payload.ease !== undefined) {
                SFController.setCameraEasing(state.api, action.payload.ease);
            }
            */

            if (action.payload.fov !== undefined) {
                SFController.setFov(state.api, action.payload.fov);
            }

            if (action.payload.userInteraction !== undefined) {
                SFController.setUserInteraction(
                    state.api,
                    action.payload.userInteraction,
                );
                state.userInteraction = action.payload.userInteraction;
            } else {
                state.enableInteractionDuringAnimation =
                    action.payload.userInteractionDuringAnimation !== undefined
                        ? action.payload.userInteractionDuringAnimation
                        : false;
            }

            if (
                action.payload.endActions !== undefined &&
                action.payload.endActionsCallback !== undefined
            ) {
                SFController.setCameraLookAtEndAnimationCallback(
                    state.api,
                    action.payload.endActionsCallback,
                    action.payload.endActions,
                );
            }

            SFController.setCameraLookAt(
                state.api,
                action.payload.position,
                action.payload.target,
                action.payload.duration,
            );
        },
        setPresetActions: (
            state,
            action: PayloadAction<PresetActionsData[]>,
        ) => {
            state.presetActions = action.payload;
        },
        setConfigurationAction: (
            state,
            action: PayloadAction<SFConfigurationAction>,
        ) => {
            // If reset then enable all scene objects.
            if (action.payload.reset === true) {
                state.sceneObjects.forEach((sceneObj) => {
                    if (sceneObj.name != null) {
                        if (!state.visibleObjects.includes(sceneObj.name)) {
                            const obj = getSceneObjectsByName(
                                state.sceneObjects,
                                sceneObj.name,
                            );
                            if (obj.length > 0) {
                                Object.values(obj).forEach((o) => {
                                    SFController.showObject(
                                        state.api,
                                        o.instanceID,
                                    );
                                });

                                state.visibleObjects.push(sceneObj.name);
                            }
                        }
                    }
                });
            }

            const getAllSceneObjects = (
                objects: string[],
                allSceneObjects: SFSceneObject[],
            ) => {
                const theObjects: SFSceneObject[] = [];

                objects.forEach((objName) => {
                    const obj = getSceneObjectsByName(allSceneObjects, objName);
                    if (obj.length > 0) {
                        obj.forEach((o) => {
                            if (!theObjects.includes(o)) {
                                theObjects.push(o);
                            }
                        });
                    }
                });
                return theObjects;
            };

            // Show the enabled scene objects.
            if (action.payload.enable) {
                const enabledObj = getAllSceneObjects(
                    action.payload.enable,
                    state.sceneObjects,
                );

                enabledObj.forEach((objName) => {
                    if (!state.visibleObjects.includes(objName.name)) {
                        SFController.showObject(state.api, objName.instanceID);
                        state.visibleObjects.push(objName.name);
                    }
                });
            }

            // Hide the disabled scene objects.
            if (action.payload.disable) {
                const disabledObj = getAllSceneObjects(
                    action.payload.disable,
                    state.sceneObjects,
                );

                disabledObj.forEach((objName) => {
                    if (state.visibleObjects.includes(objName.name)) {
                        SFController.hideObject(state.api, objName.instanceID);

                        state.visibleObjects.splice(
                            state.visibleObjects.indexOf(objName.name),
                            1,
                        );
                    }
                });
            }
        },
    },
});

export const {
    setApi,
    resetAll,
    setError,
    checkReady,
    setVisible,
    setHotspotsVisible,
    setAllHotspotsVisible,
    setHotspotTooltipsVisible,
    unselectHotspot,
    setPlayState,
    setAnimation,
    setAnimationEndCallback,
    setAnimationDrag,
    setAnnotions,
    setMaterials,
    setCycleMode,
    setSpeed,
    setSeekTo,
    setModelLoadProgress,
    setTextureLoadProgress,
    setUserInteraction,
    setUserInteractionDuringAnimation,
    setHotspotIndex,
    setAnimations,
    setSceneObjects,
    setAnimationtate,
    setCameraState,
    setCameraActionEndCallback,
    setHotspotState,
    setClickData,
    setCameraAction,
    setCameraConstraints,
    setEnableCameraConstraints,
    setConfigurationAction,
    setMaterialAction,
    setPresetActions,
    setShouldShowHotspots,
    gotoHotspot,
} = sketchfabSlice.actions;

export default sketchfabSlice.reducer;
