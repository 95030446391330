import { Action } from './action';

export type SketchfabViewData = {
    uid: string;
    backgroundColor?: number[];
    startActions: Action[];
    sceneObjects?: ViewDataSceneObject[];
    defaultActions?: Action[];
};

export type ViewDataSceneObject = {
    sceneId: string;
    objects: string[];
};

export type SketchfabHotspotData = {
    id: string;
    style?: HotspotIconType;
    title?: string;
    actions: Action[];
};

export enum HotspotIconType {
    AR = 'ar',
    ON = 'on',
    ADD = 'add',
    SETTINGS = 'settings',
}

export enum SFAnimationState {
    IDLE = 'idle',
    PLAY = 'play',
    STOP = 'stop',
    CHANGE = 'change',
    ENDED = 'ended',
}

export enum SFCameraState {
    IDLE = 'idle',
    START = 'start',
    STOP = 'stop',
    CENTERED = 'centered',
}

export enum SFHotspotStateType {
    CHANGE = 'change',
    BLUR = 'blur',
    FOCUS = 'focus',
    ENTER = 'enter',
    LEAVE = 'leave',
    SELECT = 'select',
}

export enum SFCycleMode {
    ONE = 'one',
    ALL = 'all',
    LOOP_ONE = 'loopOne',
    LOOP_ALL = 'loopAll',
}

export interface SFAnimation {
    uid: string;
    name: string;
    duration: number;
    index: number;
}

export interface SFAnnotation {
    uid: string;
    name: string;
    position: number[];
    target: number[];
    eye: number[];
    visible: boolean;
}

export interface SFSceneObject {
    instanceID: number;
    name: string;
    nodeMask: number;
    materialID: string;
    children: SFSceneObject[];
    type: string;
    localMatrix: number[];
    worldMatrix: number[];
}

export interface SFMaterial {
    channels: any;
    cullFace: string;
    id: string;
    name: string;
    reflection: number;
}

export interface SFTexture {
    name: string;
    uid: string;
    images: SFTextureImage[];
}

export interface SFTextureImage {
    uid: string;
    url: string;
    size: number;
    width: number;
    height: number;
}

export interface SFClickData {
    instanceID?: number;
    material: SFMaterial;
    normal: number[];
    position2D: number[];
    position3D: number[];
}

export interface SFHotspotState {
    type: SFHotspotStateType;
    index: number;
}

export interface SFCameraConstraints {
    up?: number;
    down?: number;
    left?: number;
    right?: number;
    position?: number[];
    target?: number[];
    zoomIn?: number;
    zoomOut?: number;
    fov?: number;
    nearFarRatio?: number;
    useCameraConstraints?: boolean;
    usePanConstraints?: boolean;
    usePitchConstraints?: boolean;
    useYawConstraints?: boolean;
    useZoomConstraints?: boolean;
    userInteraction?: boolean;
    focusOnVisibleGeometry?: boolean;
    recenter?: boolean;
    preventFocus?: boolean;
    enableContraints?: boolean;
}
