import ReactGA from 'react-ga';

import { Config } from 'types/config';
import { getConfig } from 'utils/config';

const isTrackingActive = (): boolean => {
    const config: Config = getConfig();
    return (
        !!config.googleAnalyticsTrackingID &&
        config.googleAnalyticsReportingActive
    );
};

export const initializeGA = () => {
    const config: Config = getConfig();
    if (!isTrackingActive()) {
        return;
    }
    ReactGA.initialize(config.googleAnalyticsTrackingID);
};

export const trackPageView = (pagePath: string) => {
    if (!isTrackingActive()) {
        console.log(`GA pageview: ${pagePath}`);
        return;
    }
    ReactGA.pageview(pagePath);
};

export const trackModalView = (modalPath: string) => {
    if (!isTrackingActive()) {
        console.log(`GA modal view: ${modalPath}`);
        return;
    }
    ReactGA.pageview(modalPath);
};

export const trackEvent = (
    category: string,
    action: string,
    label?: string,
    value?: number,
) => {
    if (!isTrackingActive()) {
        console.log(
            `GA event, category: ${category}, action: ${action}, label: ${label}, value: ${value}`,
        );
        return;
    }
    ReactGA.event({
        category,
        action,
        label,
        value,
    });
};
