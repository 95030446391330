export function evaluatePolySegment(xVals, segmentCoeffs, x) {
    let i = binarySearch(xVals, x);
    if (i < 0) {
        i = -i - 2;
    }
    i = Math.max(0, Math.min(i, segmentCoeffs.length - 1));
    return evaluatePoly(segmentCoeffs[i], x - xVals[i]);
}
export function evaluatePoly(c, x) {
    const n = c.length;
    if (n == 0) {
        return 0;
    }
    let v = c[n - 1];
    for (let i = n - 2; i >= 0; i--) {
        v = x * v + c[i];
    }
    return v;
}
export function trimPoly(c) {
    let n = c.length;
    while (n > 1 && c[n - 1] == 0) {
        n--;
    }
    return (n == c.length) ? c : c.subarray(0, n);
}
export function checkMonotonicallyIncreasing(a) {
    for (let i = 0; i < a.length; i++) {
        if (!isFinite(a[i])) {
            throw new Error("Non-finite number detected.");
        }
        if (i > 0 && a[i] < a[i - 1]) {
            throw new Error("Number sequence is not monotonically increasing.");
        }
    }
}
export function checkStrictlyIncreasing(a) {
    for (let i = 0; i < a.length; i++) {
        if (!isFinite(a[i])) {
            throw new Error("Non-finite number detected.");
        }
        if (i > 0 && a[i] <= a[i - 1]) {
            throw new Error("Number sequence is not strictly increasing.");
        }
    }
}
export function checkFinite(a) {
    for (let i = 0; i < a.length; i++) {
        if (!isFinite(a[i])) {
            throw new Error("Non-finite number detected.");
        }
    }
}
export function binarySearch(a, key) {
    let low = 0;
    let high = a.length - 1;
    while (low <= high) {
        const mid = (low + high) >>> 1;
        const midVal = a[mid];
        if (midVal < key) {
            low = mid + 1;
        }
        else if (midVal > key) {
            high = mid - 1;
        }
        else if (midVal == key) {
            return mid;
        }
        else {
            throw new Error("Invalid number encountered in binary search.");
        }
    }
    return -(low + 1);
}
export function getMedian(a) {
    const n = a.length;
    if (n < 1) {
        return NaN;
    }
    const a2 = new Float64Array(a);
    a2.sort();
    const m = Math.floor(n / 2);
    if (n % 2 == 0) {
        return (a2[m - 1] + a2[m]) / 2;
    }
    else {
        return a2[m];
    }
}
