import React, { useEffect, useState } from 'react';

import appActions from '../../actions/app';
import configActions from '../../actions/config';
import settingsActions from '../../actions/settings';
import { useAppDispatch, useAppSelector } from '../../app/hook';
import { selectDefaultLocale } from '../../selectors/settings';
import { ErrorType } from '../../utils/error';
import { initializeGA } from '../../utils/ga';
import i18n from '../../utils/i18next';
import { getLocaleFromParams } from '../../utils/params';
import { getSettings } from '../../utils/settings';
import Loader from '../Loader';

const ConfigLoader: React.FC = ({ children }) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [hasError, setHasError] = useState(false);
    const dispatch = useAppDispatch();

    const defaultLanguageInStore = useAppSelector(selectDefaultLocale);

    // Getting and save settings in to store
    useEffect(() => {
        const settings = getSettings();
        dispatch(settingsActions.setSettings(settings));
    }, [dispatch]);

    // Init language from params
    useEffect(() => {
        const lang = getLocaleFromParams();
        if (isLoaded && lang) {
            i18n.changeLanguage(lang);
        }
    }, [isLoaded]);

    // Setting the correct language for localization
    useEffect(() => {
        if (
            defaultLanguageInStore &&
            isLoaded &&
            i18n.getCurrentLanguage() !== defaultLanguageInStore
        ) {
            i18n.changeLanguage(defaultLanguageInStore);
        }
    }, [defaultLanguageInStore, isLoaded]);

    useEffect(() => {
        const loadConfig = async () => {
            const result = await configActions.get();

            if ('data' in result && result.data) {
                i18n.init(result.data);
                dispatch(configActions.setConfig(result.data));
                initializeGA();
            }

            if ('errorMessage' in result && result.errorMessage) {
                dispatch(
                    appActions.setError({
                        title: 'Unknown error',
                        text: 'Error while loading the configuration file',
                        type: ErrorType.UNKNOWN,
                    }),
                );
                setHasError(true);
            }

            setIsLoaded(true);
        };

        loadConfig();
    }, [dispatch]);

    if (!isLoaded) {
        return <Loader />;
    }

    if (hasError) {
        return null;
    }

    return <div>{children}</div>;
};

export default ConfigLoader;
