import React, { useState } from 'react';
import { motion } from 'framer-motion';

import { Trans } from 'react-i18next';
import styleVariables from 'assets/styles/_variables.module.scss';
import MenuToggle from 'components/Icon/MenuToggle';
import { Project, ProjectObject } from 'types/project';
import appActions from 'actions/app';
import { useAppDispatch } from 'app/hook';
import { UserAction } from 'types/action';
import clsx from 'clsx';
import Close from '../Icon/Close';

import './styles.scss';

type MainMenuProps = {
    project: Project | undefined;
};

const MainMenu: React.FC<MainMenuProps> = ({ project }) => {
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const dispatch = useAppDispatch();

    const handleActiveObjectClick = (object: ProjectObject) => {
        dispatch(appActions.setActiveObject(object));
    };

    const handleMenuToggleClick = (value: boolean) => {
        setIsMenuOpen(value);
    };

    const handleViewpointClick = (viewpointId: string) => {
        if (!viewpointId || !project) {
            return;
        }

        const foundActions: string[] = project.setup.actions
            .filter((action: UserAction): boolean => action.id === viewpointId)
            .map((action: UserAction): string => action.id);

        dispatch(
            appActions.setUserActions(
                foundActions.length ? foundActions : ['default'],
            ),
        );
    };

    return (
        <div
            className={clsx('main-menu', {
                'main-menu-closed': !isMenuOpen,
                'main-menu-opened': isMenuOpen,
            })}
        >
            <section
                className="toggle"
                role="presentation"
                onClick={() => handleMenuToggleClick(true)}
            >
                <MenuToggle />
            </section>

            <section className="content">
                <div className="header">
                    <div
                        className="close"
                        role="presentation"
                        onClick={() => handleMenuToggleClick(false)}
                    >
                        <Close color={styleVariables.colorGray0} />
                    </div>

                    <div className="sub-title">
                        <Trans>3D_CONSTRUCTION_SITE</Trans>
                    </div>

                    <div className="title">
                        <div className="upper-line">
                            <Trans>{project?.title}</Trans>
                        </div>

                        <div className="bottom-line">
                            <Trans>{project?.subtitle}</Trans>
                        </div>
                    </div>
                </div>

                <div className="content">
                    <div className="viewpoints">
                        <Trans>VIEWPOINTS</Trans>
                    </div>

                    {project?.setup?.actions?.map(
                        (userAction: UserAction) =>
                            userAction.visible !== false && (
                                <motion.button
                                    key={userAction.id}
                                    type="button"
                                    onClick={() =>
                                        handleViewpointClick(userAction.id)
                                    }
                                    whileTap={{ scale: 0.9 }}
                                >
                                    <Trans>{userAction.title}</Trans>
                                </motion.button>
                            ),
                    )}
                </div>

                <div className="footer">
                    {project?.objects?.map((object: ProjectObject) => (
                        <motion.button
                            key={object.actionId}
                            type="button"
                            onClick={() => handleActiveObjectClick(object)}
                            whileTap={{ scale: 0.9 }}
                        >
                            <Trans>Show {object.title}</Trans>
                        </motion.button>
                    ))}
                </div>
            </section>
        </div>
    );
};

export default MainMenu;
