export const ROOT_ID = 'baxter-isa-tool';
export const PARAMS = {
    LOCALE: 'data-locale',
    ENV_PATH: 'data-environment-path',
};

export const getRootElement = () => document.getElementById(ROOT_ID);

export const getLocaleFromParams = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    getRootElement()?.getAttribute(PARAMS.LOCALE);
export const getEnvPathFromParams = () =>
    // eslint-disable-next-line implicit-arrow-linebreak
    getRootElement()?.getAttribute(PARAMS.ENV_PATH);
