/* eslint-disable no-await-in-loop */
import React, { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { SketchfabViewer, useSketchfab } from 'sketchfab-viewer-react';

import { useAppDispatch, useAppSelector } from 'app/hook';
import { Action, ActionType, UserAction } from 'types/action';
import { Project } from 'types/project';
import { SketchfabViewData } from 'types/sketchfab';
import { setAllHotspotsVisible } from 'reducers/sketchfab';
import { selectActiveObject, selectUserActions } from 'selectors/app';
import appActions from 'actions/app';
import MainMenu from 'components/MainMenu';
import SimpleModal from 'components/SimpleModal';
import ProgressLoader from 'components/ProgressLoader';

import './styles.scss';

const CONSTRAINTS = {
    enabled: true,
    id: 'default',
    ignoreGlobalConstraints: false,
    zoomIn: 2.5,
    zoomOut: 0,
    up: 90,
    down: 20,
    useCameraConstraints: true,
    usePanConstraints: false,
    usePitchConstraints: true,
    useYawConstraints: false,
    useZoomConstraints: true,
};

type RoomTemplate3DViewProps = {
    project: Project;
};

const PresentProject3DView: React.FC<RoomTemplate3DViewProps> = ({
    project,
}) => {
    const [viewData, setViewData] = useState<SketchfabViewData | undefined>();
    const [activeObjectVisible, setActiveObjectVisible] =
        useState<boolean>(false);
    const activeObject = useAppSelector(selectActiveObject);
    const userActions = useAppSelector(selectUserActions);
    const dispatch = useAppDispatch();

    const { uid } = project.template.viewData;

    const {
        setCameraLookAtCustom,
        setCameraConstraints,
        api,
        isSketchfabLoading,
        setIsLoading,
        loadProgress,
    } = useSketchfab();

    const handleActiveObjectClear = () => {
        setActiveObjectVisible(false);
        dispatch(appActions.setActiveObject(undefined));
    };

    const executeActions = async () => {
        if (!project?.template?.viewData) {
            return;
        }

        for (const userActionId of userActions) {
            const pendingUserAction: Action | undefined =
                project.setup.actions.find(
                    (userAction: UserAction): boolean =>
                        userAction.id === userActionId,
                );

            if (!pendingUserAction) {
                return;
            }

            switch (pendingUserAction.type) {
                case ActionType.SET_CAMERA:
                    if (!pendingUserAction.data || pendingUserAction?.id === 'ceiling-mount') {
                        return;
                    }
                    // eslint-disable-next-line no-case-declarations
                    const { position, target, duration } =
                        pendingUserAction.data;
                    await setCameraLookAtCustom(
                        position,
                        target,
                        duration,
                        undefined,
                        false,
                        true,
                    );
                    // ugly hack that disables constrains for plan action as applying
                    // the constraints in this case causes camera to jump to a bad position
                    if (pendingUserAction?.id !== 'plan') {
                        await setCameraConstraints(CONSTRAINTS);
                    }
                    break;
                default:
                    break;
            }
        }
    };

    const prefetchImageForActiveObject = () => {
        if (activeObject) {
            const image = new Image();
            image.src = activeObject.previewImageUrl;
        }
    };

    useEffect(() => {
        if (!isSketchfabLoading && project?.template?.viewData !== undefined) {
            setViewData(project.template.viewData);
        }
    }, [project, isSketchfabLoading]);

    useEffect(() => {
        if (!isSketchfabLoading && activeObject?.actionId) {
            dispatch(setAllHotspotsVisible(false));
            dispatch(
                appActions.setUserActions([
                    activeObject?.actionId,
                ]),
            );
        }
    }, [activeObject, isSketchfabLoading]);

    useEffect(() => {
        if (isSketchfabLoading || !viewData || !project) {
            return;
        }

        prefetchImageForActiveObject();
        executeActions().then(() => {
            if (activeObject && !activeObjectVisible) {
                setActiveObjectVisible(true);
            }
        });
    }, [userActions]);

    useEffect(() => {
        let timeoutId: ReturnType<typeof setTimeout>;
        if (api && !isSketchfabLoading) {
            setIsLoading(false);
            // this is a hack. for some reason, the camera is not moving
            // if the dispatch is called immediately
            timeoutId = setTimeout(() => dispatch(appActions.setUserActions(['default'])), 1000);
        }
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [api, isSketchfabLoading]);

    return (
        <div className="view3dcontainer">
            <SketchfabViewer
                uid={uid}
                loadingComponent={
                    isSketchfabLoading ? (
                        <ProgressLoader progress={loadProgress} />
                    ) : undefined
                }
            />

            {viewData && !isSketchfabLoading && <MainMenu project={project} />}

            <AnimatePresence>
                {!!activeObject && activeObjectVisible && (
                    <SimpleModal
                        onClose={() => handleActiveObjectClear()}
                        key="modal"
                    >
                        <img
                            alt={activeObject.title}
                            src={activeObject.previewImageUrl}
                        />
                    </SimpleModal>
                )}
            </AnimatePresence>
        </div>
    );
};

export default PresentProject3DView;
