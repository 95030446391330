// eslint-disable-next-line import/order
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Config } from '../types/config';

type ConfigState = {
    fullConfig: Config;
};

const initialState: ConfigState = {
    fullConfig: {
        googleAnalyticsReportingActive: false,
        googleAnalyticsTrackingID: '',
        i18n: {},
        projects: [],
    },
};

export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        setConfig: (state, action: PayloadAction<Config>) => {
            state.fullConfig = action.payload;
        },
    },
});

export default configSlice.reducer;
