import store from 'app/store';
import { Config } from 'types/config';

export function getConfig(): Config {
    return store.getState()?.config?.fullConfig || {};
}

export function getLanguagesFromConfig(config: Config) {
    return Object.entries(config.i18n).map(([key, data]) => {
        const { name, iconUrl } = data;

        return {
            key,
            name,
            iconUrl,
        };
    });
}
