import { useSketchfab } from 'sketchfab-viewer-react';
import { SFMaterialAction } from 'types/action';
import { SFMaterial, SFTexture } from 'types/sketchfab';

function showError(error: string) {
    if (error !== null && error !== '') {
        // eslint-disable-next-line no-console
        return false;
    }
    return true;
}

function setHotspotTooltipVisible(api: any, visible: boolean) {
    if (visible) {
        api.showAnnotationTooltips((err: any) => {
            showError(err);
        });
    } else {
        api.hideAnnotationTooltips((err: any) => {
            showError(err);
        });
    }
}

function setHotspotVisible(api: any, index: number, visible: boolean) {
    if (visible) {
        // console.log("showAnnotation: "+ index);
        api.showAnnotation(index, (err: any) => {
            showError(err);
        });
    } else {
        // console.log("hideAnnotation: "+ index);
        api.hideAnnotation(index, (err: any) => {
            showError(err);
        });
    }
}

function gotoHotspot(
    api: any,
    index: number,
    preventAnimation: boolean,
    preventMove: boolean,
) {
    api.gotoAnnotation(
        index,
        {
            preventCameraAnimation: preventAnimation,
            preventCameraMove: preventMove,
        },
        (err: any) => {
            showError(err);
        },
    );
}

// Somehow does not work in Sketchfab API.
function unselectHotspot(api: any) {
    api.unselectAnnotation((err: any) => {
        showError(err);
    });
}

function setHotspotCameraTransition(
    api: any,
    preventAnimation: boolean,
    preventMove: boolean,
) {
    api.setAnnotationCameraTransition(
        preventAnimation,
        preventMove,
        (err: any) => {
            showError(err);
        },
    );
}

function setPlayState(api: any, play: boolean) {
    if (play) {
        api.play((err: any) => {
            showError(err);
        });
    } else {
        api.pause((err: any) => {
            showError(err);
        });
    }
}

function seekTo(api: any, seconds: number) {
    api.seekTo(seconds, (err: any) => {
        showError(err);
    });
}

function setCycleMode(api: any, mode: string) {
    api.setCycleMode(mode, (err: any) => {
        showError(err);
    });
}

function setSpeed(api: any, speed: number) {
    api.setSpeed(speed, (err: any) => {
        showError(err);
    });
}

function setUserInteraction(api: any, enable: boolean) {
    setTimeout(() => {
        api.setUserInteraction(enable, (err: any) => {
            showError(err);
        });
    }, 3000);
}

function setCurrentAnimationByUID(api: any, uid: string) {
    api.setCurrentAnimationByUID(uid, (err: any) => {
        showError(err);
    });
}

function getAnimations(api: any, callback: Function) {
    api.getAnimations((err: any, animations: any[]) => {
        showError(err);
        callback(animations);
    });
}

function setCameraLookAt(
    api: any,
    position: number[],
    target: number[],
    duration: number,
) {
    // we can't do that as that breaks rules of hooks
    const { setCameraLookAt: setCam } = useSketchfab();
    setCam(position, target, duration);
    /*
    api.setCameraLookAt(position, target, duration, (err: any) => {
        showError(err);
    });
    */
}

function recenterCamera(api: any) {
    api.recenterCamera((err: any) => {
        showError(err);
    });
}

function focusOnVisibleGeometries(api: any) {
    api.focusOnVisibleGeometries((err: any) => {
        showError(err);
    });
}

function setCameraEasing(api: any, easing: string) {
    api.setCameraEasing(easing);
}

function setCameraConstraints(api: any, options: any) {
    api.setCameraConstraints(options, (err: any) => {
        showError(err);
    });
}

function setEnableCameraConstraints(
    api: any,
    enable: boolean,
    preventFocus: boolean,
) {
    api.setEnableCameraConstraints(
        enable,
        { preventCameraConstraintsFocus: preventFocus },
        (err: any) => {
            showError(err);
        },
    );
}

function setCameraLookAtEndAnimationCallback(
    api: any,
    callback: Function,
    callbackProps: any,
) {
    api.setCameraLookAtEndAnimationCallback((err: any) => {
        showError(err);

        if (callbackProps !== undefined) callback(callbackProps);
        else callback();
    });
}

function setMaterialAction(api: any, action: SFMaterialAction) {
    const updateMaterial = (
        // eslint-disable-next-line @typescript-eslint/no-shadow
        api: any,
        // eslint-disable-next-line @typescript-eslint/no-shadow
        action: SFMaterialAction,
        material: SFMaterial | undefined,
        textureUID: string | undefined,
    ) => {
        // console.log("updateMaterial", material, textureUID);

        if (
            material !== undefined &&
            action.channels !== undefined &&
            material.channels !== undefined
        ) {
            const theTextureChannels: string[] = [];

            Object.keys(action.channels).forEach((c) => {
                if (material.channels[c] !== undefined) {
                    Object.keys(action.channels[c]).forEach((p) => {
                        if (p === 'texture' && textureUID !== undefined) {
                            theTextureChannels.push(c);
                            // eslint-disable-next-line no-param-reassign
                            material.channels[c][p] = { uid: textureUID };
                            // eslint-disable-next-line no-param-reassign
                        } else material.channels[c][p] = action.channels[c][p];
                    });
                }
            });

            api.setMaterial(material, () => {
                // console.log("Material updated", material);
            });

            if (theTextureChannels.length > 0) {
                theTextureChannels.forEach((c) => {
                    if (action.uvScale && action.uvScale.length === 2) {
                        // console.log("setUVScale", action.uvScale[0], action.uvScale[1]);
                        api.setUVScale(
                            material,
                            c,
                            action.uvScale[0],
                            action.uvScale[1],
                        );
                    }
                    if (action.uvOffset && action.uvOffset.length === 2) {
                        // console.log("setUVOffset", action.uvOffset[0], action.uvOffset[1]);
                        api.setUVOffset(
                            material,
                            c,
                            action.uvOffset[0],
                            action.uvOffset[1],
                        );
                    }
                    if (action.uvRotation) {
                        // console.log("setUVRotation", action.uvRotation);
                        api.setUVRotation(material, c, action.uvRotation);
                    }
                });
            }
        }
    };

    api.getMaterialList((err: any, materials: SFMaterial[]) => {
        showError(err);

        if (materials) {
            const theMaterial = materials.find(
                (mat) => mat.name === action.materialId,
            );
            if (theMaterial) {
                if (action.texture || action.video) {
                    api.getTextureList((error: any, textures: SFTexture[]) => {
                        showError(error);

                        if (textures) {
                            let theTextureUID = null;
                            const theSrc = action.texture
                                ? action.texture
                                : action.video;
                            Object.values(textures).forEach((t) => {
                                const thefoundTex = t.images.find(
                                    (tex) => tex.url === theSrc,
                                );
                                if (thefoundTex) {
                                    theTextureUID = t.uid;
                                }
                            });

                            if (theTextureUID === null) {
                                if (action.texture) {
                                    api.addTexture(
                                        action.texture,
                                        (error2: any, textureUID: string) => {
                                            showError(error2);

                                            if (textureUID) {
                                                updateMaterial(
                                                    api,
                                                    action,
                                                    theMaterial,
                                                    textureUID,
                                                );
                                            }
                                        },
                                    );
                                } else {
                                    const theOptions = {
                                        loop: action.loop || false,
                                        mute: action.mute || false,
                                    };
                                    api.addVideoTexture(
                                        action.video,
                                        theOptions,
                                        (error3: any, textureUID: string) => {
                                            showError(error3);

                                            if (textureUID) {
                                                updateMaterial(
                                                    api,
                                                    action,
                                                    theMaterial,
                                                    textureUID,
                                                );
                                            }
                                        },
                                    );
                                }
                            } else {
                                updateMaterial(
                                    api,
                                    action,
                                    theMaterial,
                                    theTextureUID,
                                );
                            }
                        }
                    });
                } else {
                    updateMaterial(api, action, theMaterial, undefined);
                }
            }
        }
    });
}

function setFov(api: any, fov: number) {
    api.setFov(fov, (err: any) => {
        showError(err);
    });
}

function showObject(api: any, instanceID: number) {
    // console.log("show :" + instanceID);
    api.show(instanceID, (err: any) => {
        showError(err);
    });
}

function hideObject(api: any, instanceID: number) {
    // console.log("hide :" + instanceID);
    api.hide(instanceID, (err: any) => {
        showError(err);
    });
}

function resetCamera(api: any) {
    // Nasty trick to reset the camera when constraints are set and pan disabled.
    api.getCameraLookAt((err: any, camera: any) => {
        if (!err && camera) {
            const options = {
                position: camera.position,
                target: camera.target,
            };
            api.setCameraConstraints(options, (error: any) => {
                showError(error);
            });
        }
    });
}

const SFController = {
    setHotspotTooltipVisible,
    setHotspotVisible,
    setHotspotCameraTransition,
    setPlayState,
    seekTo,
    setUserInteraction,
    setCurrentAnimationByUID,
    setCycleMode,
    setSpeed,
    setCameraLookAt,
    recenterCamera,
    setCameraEasing,
    setCameraConstraints,
    setEnableCameraConstraints,
    setCameraLookAtEndAnimationCallback,
    setFov,
    setMaterialAction,
    focusOnVisibleGeometries,
    gotoHotspot,
    unselectHotspot,
    getAnimations,
    showObject,
    hideObject,
    resetCamera,
};

export default SFController;
