import { configSlice } from 'reducers/config';
import configService from 'services/configService';
import { getResponseErrorMessage } from 'utils/responseError';

const get = async () => {
    try {
        return {
            data: await configService.get(),
        };
    } catch (err) {
        return {
            errorMessage: getResponseErrorMessage(err),
        };
    }
};

const configActions = {
    ...configSlice.actions,
    get,
};

export default configActions;
