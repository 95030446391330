export class ConfigError extends Error {}

export class ConfigNotFoundError extends ConfigError {
    constructor(aFile: string) {
        super(`Config file not found: ${aFile}`);
    }
}

export class ConfigParseError extends ConfigError {
    constructor(aFile: string) {
        super(`Config file is invalid: ${aFile}`);
    }
}
