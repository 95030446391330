import React from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';

import styleVariables from 'assets/styles/_variables.module.scss';
import Close from 'components/Icon/Close';

import './styles.scss';

export type SimpleModalProps = {
    children?: React.ReactNode;
    className?: string;
    onClose?: () => void;
};

const SimpleModal: React.FC<SimpleModalProps> = ({
    children,
    className,
    onClose,
}) => (
    <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
            jsx-a11y/no-static-element-interactions
        */}
        <motion.div
            className={clsx('simplemodal-overlay', className)}
            onClick={onClose}
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1, ease: [0.25, 1, 0.33, 1] }}
        />
        <motion.div
            className={clsx('simplemodal', className)}
            role="presentation"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            transition={{
                duration: 0.5,
                ease: [0.25, 1, 0.33, 1],
                delay: 0.2,
            }}
        >
            {children}
            <div className="close" role="presentation" onClick={onClose}>
                <Close color={styleVariables.colorGray0} />
            </div>
        </motion.div>
    </>
);

export default SimpleModal;
