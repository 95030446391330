import React from 'react';

import clsx from 'clsx';
import Scrollbars from 'react-custom-scrollbars-2';

import Button, { ButtonType } from 'components/Button';
import Close from 'components/Icon/Close';

import './styles.scss';

export type ModalProps = {
    children?: React.ReactNode;
    className?: string;
    hasRoundedCorners?: boolean;
    onClose?: () => void;
};

const Modal: React.FC<ModalProps> = ({
    children,
    className,
    hasRoundedCorners,
    onClose,
}) => {
    const handleContentClick = (event: React.MouseEvent) => {
        event.stopPropagation();
    };

    return (
        <div
            className={clsx('modal-overlay', className)}
            onClick={onClose}
            role="presentation"
        >
            <div
                className={clsx('modal', className, {
                    'modal--rounded': !!hasRoundedCorners,
                })}
            >
                <div
                    className="modal-header"
                    onClick={handleContentClick}
                    role="presentation"
                >
                    <Button
                        className="modal-close"
                        onClick={onClose}
                        type={ButtonType.LINK}
                    >
                        <Close />
                    </Button>
                </div>
                <Scrollbars
                    autoHeight
                    autoHeightMin={100}
                    autoHeightMax="calc((100vh - 75px) - 128px)"
                    autoHide
                >
                    <div
                        className="modal-content"
                        onClick={handleContentClick}
                        role="presentation"
                    >
                        {children}
                    </div>
                </Scrollbars>
            </div>
        </div>
    );
};

export default Modal;
