import { configureStore } from '@reduxjs/toolkit';

import appReducer from 'reducers/app';
import configReducer from 'reducers/config';
import errorReducer from 'reducers/error';
import presentReducer from 'reducers/present';
import settingsReducer from 'reducers/settings';
import sketchfabReducer from 'reducers/sketchfab';

const store = configureStore({
    reducer: {
        app: appReducer,
        config: configReducer,
        error: errorReducer,
        present: presentReducer,
        settings: settingsReducer,
        sketchfab: sketchfabReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
    devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
