import React from 'react';

import 'moment-timezone';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import appStore from 'app/store';
import App from 'components/App';
import ConfigLoader from 'components/ConfigLoader';
import ErrorModal from 'components/ErrorModal';
import Notification from 'components/Notification';
import NotificationProvider from 'providers/NotificationProvider';
// import reportWebVitals from 'reportWebVitals';
import { ROOT_ID } from 'utils/params';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={appStore}>
            <NotificationProvider>
                <ConfigLoader>
                    <App />
                </ConfigLoader>
                <ErrorModal />
                <Notification />
            </NotificationProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById(ROOT_ID),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
