import { checkStrictlyIncreasing, binarySearch } from "./Utils.js";
export function createNearestNeighborInterpolator(xVals, yVals) {
    const xVals2 = Float64Array.from(xVals);
    const yVals2 = Float64Array.from(yVals);
    const n = xVals2.length;
    if (n != yVals2.length) {
        throw new Error("Dimension mismatch for xVals and yVals.");
    }
    if (n == 0) {
        return function (_x) {
            return NaN;
        };
    }
    if (n == 1) {
        return function (_x) {
            return yVals2[0];
        };
    }
    checkStrictlyIncreasing(xVals2);
    return function (x) {
        let i = binarySearch(xVals2, x);
        if (i >= 0) {
            return yVals2[i];
        }
        i = -i - 1;
        if (i == 0) {
            return yVals2[0];
        }
        if (i >= n) {
            return yVals2[n - 1];
        }
        const d = x - xVals2[i - 1];
        const w = xVals2[i] - xVals2[i - 1];
        return (d + d < w) ? yVals2[i - 1] : yVals2[i];
    };
}
