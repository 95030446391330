import React from 'react';

import styleVariables from 'assets/styles/_variables.module.scss';
import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const Close: React.FC<IconProps> = ({
    color = styleVariables.colorPurple0,
    height = 24,
    opacity,
    width = 24,
}) => (
    <IconBase height={height} viewBox="0 0 24 24" width={width}>
        <path
            d="M13.4658 12.0001L19.6959 5.76971C20.1014 5.36445 20.1014 4.70921 19.6959 4.30395C19.2907 3.89869 18.6354 3.89869 18.2302 4.30395L11.9999 10.5343L5.76982 4.30395C5.36438 3.89869 4.70933 3.89869 4.30408 4.30395C3.89864 4.70921 3.89864 5.36445 4.30408 5.76971L10.5342 12.0001L4.30408 18.2305C3.89864 18.6358 3.89864 19.291 4.30408 19.6963C4.50604 19.8984 4.77159 20 5.03695 20C5.30231 20 5.56767 19.8984 5.76982 19.6963L11.9999 13.4659L18.2302 19.6963C18.4323 19.8984 18.6977 20 18.963 20C19.2284 20 19.4938 19.8984 19.6959 19.6963C20.1014 19.291 20.1014 18.6358 19.6959 18.2305L13.4658 12.0001Z"
            fill={color}
            fillOpacity={opacity}
            strokeOpacity="0"
        />
    </IconBase>
);

export default Close;
