import React from 'react';

import { Trans } from 'react-i18next';

import appActions from 'actions/app';
import { useAppDispatch, useAppSelector } from 'app/hook';
import Modal from 'components/Modal';
import { getError } from 'selectors/app';
import { CustomError } from 'types/error';

import './styles.scss';

const ErrorModal: React.FC = () => {
    const dispatch = useAppDispatch();
    const error: CustomError | undefined = useAppSelector<
        CustomError | undefined
    >(getError);

    const handleClose = () => {
        dispatch(appActions.setError(undefined));
    };

    return error ? (
        <Modal className="error-modal" hasRoundedCorners onClose={handleClose}>
            <div className="error-title">
                <Trans>{error.title}</Trans>
            </div>
            <div className="error-description">
                <Trans>{error.text}</Trans>
            </div>
        </Modal>
    ) : null;
};

export default ErrorModal;
