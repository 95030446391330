import React from 'react';

import './styles.scss';

const Loader: React.FC = () => (
    <div className="loadingSpinner-container">
        <div className="loadingSpinner">
            <span className="loadingSpinner-inner" />
            <span className="loadingSpinner-inner" />
            <span className="loadingSpinner-inner" />
            <span className="loadingSpinner-inner" />
        </div>
    </div>
);

export default Loader;
