import { checkStrictlyIncreasing, trimPoly, evaluatePolySegment } from "./Utils.js";
export function createLinearInterpolator(xVals, yVals) {
    const segmentCoeffs = computeLinearPolyCoefficients(xVals, yVals);
    const xValsCopy = Float64Array.from(xVals);
    return (x) => evaluatePolySegment(xValsCopy, segmentCoeffs, x);
}
export function computeLinearPolyCoefficients(xVals, yVals) {
    if (xVals.length != yVals.length) {
        throw new Error("Dimension mismatch.");
    }
    if (xVals.length < 2) {
        throw new Error("Number of points is too small.");
    }
    checkStrictlyIncreasing(xVals);
    const n = xVals.length - 1;
    const segmentCoeffs = new Array(n);
    for (let i = 0; i < n; i++) {
        const dx = xVals[i + 1] - xVals[i];
        const dy = yVals[i + 1] - yVals[i];
        const m = dy / dx;
        const c = new Float64Array(2);
        c[0] = yVals[i];
        c[1] = m;
        segmentCoeffs[i] = trimPoly(c);
    }
    return segmentCoeffs;
}
