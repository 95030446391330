import React, { useEffect } from 'react';

import useNotification from 'hooks/useNotification';

const Notification: React.FC = () => {
    const { notification, removeNotification } = useNotification();

    useEffect(() => {
        const timer = setTimeout(() => removeNotification(), 10000);

        return () => {
            clearTimeout(timer);
        };
    }, [removeNotification]);

    const handleNotificationKeyPress: React.KeyboardEventHandler<
        HTMLDivElement
    > = (event: React.KeyboardEvent) => {
        if (event.key === 'Escape') {
            removeNotification();
        }
    };

    return (
        notification && (
            <div
                className="notification-container"
                onClick={() => removeNotification()}
                onKeyUp={handleNotificationKeyPress}
                role="presentation"
            >
                <h4>{notification.title}</h4>
                <p>{notification.message}</p>
            </div>
        )
    );
};

export default Notification;
