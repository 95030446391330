import React, { useEffect, useRef, useState } from 'react';

import { Player } from '@lottiefiles/react-lottie-player';
import clsx from 'clsx';

import animationData from '../../assets/loader/loader-anim.json';
import LoaderPopup from './LoaderPopup';

import './styles.scss';

type ProgressLoaderProps = {
    progress: number;
};

const ProgressLoader: React.FC<ProgressLoaderProps> = ({ progress }) => {
    // const settings = getSettings();

    const player = useRef<Player | null>(null);

    const [showLoadingPopup, setShowloadingPopup] = useState(false);

    const timer = 500;
    const firstControlTime = 1500;
    const maxWaitingTime = 2500;

    const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
    const countdown = new Date().getTime();

    const [counter, setCounter] = useState<number>(0);
    const [firstLoadProgress, setFirstLoadProgress] = useState<number>(0);
    const [secondLoadProgress, setSecondLoadProgress] = useState<number>(0);

    useEffect(() => {
        if (player.current) {
            // eslint-disable-next-line no-unused-expressions
            player.current?.play();
        }
    }, [player]);

    useEffect(() => {
        const currentTime = countdown + (maxWaitingTime - firstControlTime);
        const diff = (currentTime - countdown) * counter;

        if (diff === firstControlTime) {
            setFirstLoadProgress(progress);
        }

        if (diff >= maxWaitingTime) {
            setSecondLoadProgress(progress);
        }

        if (counter < maxWaitingTime / timer) {
            timerRef.current = setTimeout(() => {
                setCounter(counter + 1);
            }, timer);
        }
        const proportion =
            !firstControlTime ||
            firstControlTime / maxWaitingTime >
                firstLoadProgress / secondLoadProgress;
        if (progress !== 100) {
            setShowloadingPopup(proportion);
        }
        return () => {
            if (timerRef.current) clearTimeout(timerRef.current);
        };
    }, [countdown, counter, firstLoadProgress, progress, secondLoadProgress]);

    return (
        <div className={clsx('view3dLoader')}>
            <LoaderPopup visible={showLoadingPopup} />
            <div className={clsx('block', { showFallback: showLoadingPopup })}>
                <Player
                    className="anim"
                    autoplay
                    loop
                    src={animationData}
                    ref={player}
                />
                <div className="progressBar">
                    <div
                        className="progress"
                        style={{ width: `${progress}%` }}
                    />
                </div>
            </div>
        </div>
    );
};

export default ProgressLoader;
