import React, { useEffect } from 'react';

import { useNavigate, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'app/hook';
import PresentProject3DView from 'components/PresentProject3DView';
import { Project } from 'types/project';
import { RouteType } from 'types/route';

import './styles.scss';
import { selectProject } from 'selectors/config';
import { RootState } from 'app/store';
import appActions from 'actions/app';
import { ErrorType } from 'utils/error';
import { SketchfabProvider } from 'sketchfab-viewer-react';

const PresentProject: React.FC = () => {
    const { projectId } = useParams();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const project = useAppSelector<Project | undefined>((state: RootState) =>
        selectProject(state, projectId),
    );

    useEffect(() => {
        if (project) {
            return;
        }

        dispatch(
            appActions.setError({
                title: 'Unknown error',
                text: 'Project not found',
                type: ErrorType.NOT_FOUND,
            }),
        );

        navigate(RouteType.PROJECT_OVERVIEW);
    }, [project, projectId]);

    return (project && projectId) ? (
        <SketchfabProvider key={projectId}>
            <div className="presentProjectView">
                <PresentProject3DView project={project} />
            </div>
        </SketchfabProvider>
    ) : (
        <div />
    );
};

export default PresentProject;
