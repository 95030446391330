import { HotspotIconType, SFSceneObject } from 'types/sketchfab';

import HotspotAdd from '../assets/images/hotspot-add.png';
import HotspotAR from '../assets/images/hotspot-ar.png';
import HotspotOn from '../assets/images/hotspot-on.png';
import HotspotSettings from '../assets/images/hotspot-settings.png';

type HotspotIcon = {
    [key: string]: {
        style: string;
    };
};

const ICONS: HotspotIcon = {
    [HotspotIconType.AR]: { style: HotspotAR },
    [HotspotIconType.ADD]: { style: HotspotAdd },
    [HotspotIconType.ON]: { style: HotspotOn },
    [HotspotIconType.SETTINGS]: { style: HotspotSettings },
};

export const getSceneObjectsByName = (
    sceneObjects: SFSceneObject[],
    name: string,
) => {
    const theObj: SFSceneObject[] = [];
    Object.values(sceneObjects).forEach((obj) => {
        if (obj.name === name && !theObj.includes(obj)) {
            theObj.push(obj);
        }
    });

    if (name.indexOf('*') !== -1) {
        const match: string = name.substring(0, name.indexOf('*'));
        Object.values(sceneObjects).forEach((obj) => {
            if (
                obj &&
                obj.name &&
                obj.name.startsWith(match) &&
                !theObj.includes(obj)
            ) {
                theObj.push(obj);
            }
        });
    }

    return theObj;
};

export const degToRad = (degrees: number) => (degrees * Math.PI) / 180;

export const radToDeg = (radians: number) => (radians * 180) / Math.PI;

export const generateTextureAsBase64 = async (hotspots: HotspotIconType[]) => {
    const colNumber = 6;
    const iconSize = 32;
    const padding = 2;
    const canvas = document.createElement('canvas');
    canvas.width = 256;
    canvas.height = 256;
    const ctx = canvas.getContext('2d');

    await Promise.all(
        hotspots.map(
            (item, i) =>
                new Promise((resolve) => {
                    const img = new Image();
                    img.onload = () => {
                        ctx?.drawImage(
                            img,
                            (i % colNumber) * (iconSize + padding),
                            Math.floor(i / colNumber) * (iconSize + padding),
                        );
                        resolve(true);
                    };
                    let theSrc = ICONS[HotspotIconType.ADD].style;
                    if (ICONS[item]) theSrc = ICONS[item].style;

                    img.src = theSrc;
                }),
        ),
    );

    return {
        url: canvas.toDataURL(),
        padding,
        iconSize,
        colNumber,
    };
};
