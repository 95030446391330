import React, { useMemo, useState } from 'react';

import { Notification, NotificationType } from './state';

export interface NotificationContextValue {
    notification: Notification | null;
}

export interface ActionContextValue {
    showNotification: (
        type: NotificationType,
        title: string,
        message: string,
    ) => void;
    removeNotification: () => void;
}

export const NotificationContext = React.createContext<
    NotificationContextValue & ActionContextValue
>(undefined as any);

const NotificationProvider: React.FC = ({ children }) => {
    const [notification, setNotification] = useState<Notification | null>(null);

    const removeNotification = () => setNotification(null);

    const showNotification = (
        type: NotificationType,
        title: string,
        message: string,
    ) => {
        setNotification({ type, title, message });
    };

    const contextValue: NotificationContextValue & ActionContextValue = useMemo(
        () => ({
            notification,
            showNotification,
            removeNotification,
        }),
        [notification],
    );

    return (
        <NotificationContext.Provider value={contextValue}>
            {children}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
