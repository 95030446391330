import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'app/store';
import { Config } from 'types/config';
import { Project } from 'types/project';

export const selectFullConfig = (state: RootState): Config =>
    state.config.fullConfig;
export const selectProjects = createSelector(
    [selectFullConfig],
    (config: Config): Project[] => config.projects,
);
export const selectProject = createSelector(
    [selectProjects, (_state: RootState, id: string | undefined) => id],
    (projects: Project[], id: string | undefined): Project | undefined =>
        (!id
            ? undefined
            : projects.find((project: Project): boolean => project.id === id)),
);
