import React, { useEffect, useMemo, useState } from 'react';

import { useLocation } from 'react-router-dom';

import { ContentModuleComponentMedia } from 'types/product';

export type MediaContextType = {
    hideMedia: () => void;
    isVisible: boolean;
    mediaContent: ContentModuleComponentMedia | undefined;
    selectedIndex: number;
    setMediaIndex: (index: number) => void;
    setMediaContentData: (
        data: ContentModuleComponentMedia | undefined,
    ) => void;
    showMedia: () => void;
};

export const MediaContext = React.createContext<MediaContextType>(
    undefined as any,
);

const MediaProvider: React.FC = ({ children }) => {
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [selectedIndex, setSelectedIndex] = useState<number>(0);
    const [mediaContent, setMediaContent] = useState<
        ContentModuleComponentMedia | undefined
    >(undefined);
    const hideMedia = () => {
        setIsVisible(false);
    };

    const setMediaIndex = (mediaIndex: number) => {
        setSelectedIndex(mediaIndex);
    };

    const setMediaContentData = (
        data: ContentModuleComponentMedia | undefined,
    ) => {
        setMediaContent(data);
    };

    const showMedia = () => {
        setIsVisible(true);
    };

    const location = useLocation();
    useEffect(() => {
        hideMedia();
    }, [location]);

    const contextValue: MediaContextType = useMemo(
        () => ({
            hideMedia,
            isVisible,
            mediaContent,
            selectedIndex,
            setMediaIndex,
            setMediaContentData,
            showMedia,
        }),
        [isVisible, selectedIndex, mediaContent],
    );

    return (
        <MediaContext.Provider value={contextValue}>
            {children}
        </MediaContext.Provider>
    );
};

export default MediaProvider;
