import axios, { AxiosResponse } from 'axios';

import { ConfigNotFoundError, ConfigParseError } from 'errors/configErrors';
import { Config } from 'types/config';
import { getSettings } from 'utils/settings';

const get = async (): Promise<Config | undefined> => {
    const settings = getSettings();
    const configUrl = `${settings.configPath}config/config.json`;

    if (!configUrl) {
        throw new ConfigNotFoundError(configUrl);
    }

    let response: AxiosResponse<Config>;

    try {
        response = await axios.get<Config>(configUrl);
    } catch (err) {
        throw new ConfigNotFoundError(configUrl);
    }

    if (!response?.data) {
        throw new ConfigNotFoundError(configUrl);
    }

    if (typeof response.data !== 'object') {
        throw new ConfigParseError(configUrl);
    }

    return response.data;
};

const configService = {
    get,
};

export default configService;
