import React from 'react';

import clsx from 'clsx';

import './styles.scss';

export enum ButtonType {
    MAIN = 'main',
    MAIN_ALTERNATE = 'main-alternate',
    SECONDARY = 'secondary',
    ADDITIONAL = 'additional',
    NAVIGATION = 'navigation',
    NAVIGATION_MEDIUM = 'navigation-medium',
    NAVIGATION_SMALL = 'navigation-small',
    LINK = 'link',
}

export type ButtonProps = {
    children?: React.ReactNode;
    className?: string;
    icon?: React.ReactNode;
    isDisabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    type?: ButtonType;
};

const Button: React.FC<ButtonProps> = ({
    children,
    className,
    isDisabled,
    onClick,
    type,
}) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!onClick) {
            return;
        }
        onClick(event);
    };

    return (
        <button
            className={clsx('button', className, {
                'button--main': !type || type === ButtonType.MAIN,
                'button--main-alternate': type === ButtonType.MAIN_ALTERNATE,
                'button--secondary': type === ButtonType.SECONDARY,
                'button--additional': type === ButtonType.ADDITIONAL,
                'button--navigation': type === ButtonType.NAVIGATION,
                'button--navigationMedium':
                    type === ButtonType.NAVIGATION_MEDIUM,
                'button--navigationSmall': type === ButtonType.NAVIGATION_SMALL,
                'button--link': type === ButtonType.LINK,
            })}
            disabled={isDisabled}
            onClick={handleClick}
            type="button"
        >
            {children}
        </button>
    );
};

export default Button;
