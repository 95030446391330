import React from 'react';

import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';

import PresentProject from 'components/PresentProject';
import MediaProvider from 'providers/MediaProvider';
import { RouteType } from 'types/route';

import './styles.scss';

const App: React.FC = () => (
    <HashRouter>
        <MediaProvider>
            <Routes>
                <Route
                    path={RouteType.PRESENT_PROJECT}
                    element={<PresentProject />}
                />
                <Route
                    path="*"
                    element={<Navigate to={RouteType.PRESENT_PROJECT.replace(':projectId', '1')} />}
                />
            </Routes>
        </MediaProvider>
    </HashRouter>
);

export default App;
