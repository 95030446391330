import React from 'react';

import styleVariables from 'assets/styles/_variables.module.scss';
import IconBase from 'components/Icon/IconBase';

import { IconProps } from '.';

const MenuToggle: React.FC<IconProps> = ({
    color = styleVariables.colorPurple0,
    height = 23,
    opacity,
    width = 23,
}) => (
    <IconBase height={height} viewBox="0 0 23 17" width={width}>
        <line
            x1="1.5"
            y1="1.5"
            x2="21.5"
            y2="1.5"
            fillOpacity="0"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth="3"
            strokeLinecap="round"
        />
        <line
            x1="1.5"
            y1="8.5"
            x2="21.5"
            y2="8.5"
            fillOpacity="0"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth="3"
            strokeLinecap="round"
        />
        <line
            x1="1.5"
            y1="15.5"
            x2="21.5"
            y2="15.5"
            fillOpacity="0"
            stroke={color}
            strokeOpacity={opacity}
            strokeWidth="3"
            strokeLinecap="round"
        />
    </IconBase>
);

export default MenuToggle;
