import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { CustomError } from 'types/error';
import { ErrorType } from 'utils/error';

type ErrorState = CustomError & { visible: boolean };

const initialState: ErrorState = {
    title: '',
    text: '',
    type: ErrorType.UNKNOWN,
    visible: false,
};

export const errorSlice = createSlice({
    name: 'error',
    initialState,
    reducers: {
        showError: (state, action: PayloadAction<CustomError>) => {
            state.title = action.payload.title;
            state.text = action.payload.text;
            state.visible = true;
        },
        hideError: (state) => {
            state.title = '';
            state.text = '';
            state.visible = false;
        },
    },
});

export default errorSlice.reducer;
